import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();
    const { module_game } = useSelector((state) => state.setting);

    const handleClickLogo = () => {
        if (module_game.module === 'one') {
            return;
        }

        navigate('/');
    };

    return (
        <div data-v-7c1c8a15="">
            <div data-v-7c1c8a15="" className="wrap-header" style={{ top: 0 }}>
                <div data-v-7c1c8a15="" className="header-custom">
                    <div data-v-7c1c8a15="" className="left-header">
                        <div className="router-link-exact-active active" onClick={handleClickLogo}>
                            <img
                                data-v-7c1c8a15=""
                                alt="logo"
                                className="logo-header"
                                src="https://shop.vnggames.com/static/img/logoshop.png"
                            />
                        </div>

                        <div data-v-7c1c8a15="" className="wrap-search">
                            <div data-v-7c1c8a15="" className="wrap-search-custom">
                                <div custom-value="HEADER">
                                    <div className="el-autocomplete inline-input custom-searching-button is-compact is-expand">
                                        <div className="el-input el-input--suffix">
                                            <input type="text" placeholder="Metal Slug: Awakening" className="el-input__inner" />
                                            <span className="el-input__suffix">
                                                <span className="el-input__suffix-inner">
                                                    <i className="el-input__icon el-icon-search"></i>
                                                </span>
                                            </span>
                                        </div>
                                        <div
                                            className="el-autocomplete-suggestion el-popper false shopvnggamescom"
                                            style={{ display: 'none' }}
                                        >
                                            <div className="el-scrollbar">
                                                <div className="el-autocomplete-suggestion__wrap el-scrollbar__wrap el-scrollbar__wrap--hidden-default">
                                                    <ul className="el-scrollbar__view el-autocomplete-suggestion__list"></ul>
                                                </div>
                                                <div className="el-scrollbar__bar is-horizontal">
                                                    <div className="el-scrollbar__thumb" style={{ transform: 'translateX(0%)' }}></div>
                                                </div>
                                                <div className="el-scrollbar__bar is-vertical">
                                                    <div className="el-scrollbar__thumb" style={{ transform: 'translateY(0%)' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div data-v-7c1c8a15="" className="right-header">
                        <div data-v-7c1c8a15="" className="content-right">
                            <div data-v-7c1c8a15="" className="dflex gap20">
                                {/* <div data-v-7c1c8a15="" className="wrap-candy-box">
                                    <div  className="btn-candy">
                                        <svg data-v-2284d192="" aria-hidden="true" className="icon-candy svg-icon">
                                            <use data-v-2284d192="" xlinkHref="#icon-candy"></use>
                                        </svg>
                                    </div>
                                </div> */}
                                {/* <div data-v-71b23c22="" data-v-7c1c8a15="" className="wrap-select-region">
                                    <div data-v-71b23c22=""  className="btn-region">
                                        <svg
                                            data-v-2284d192=""
                                            data-v-71b23c22=""
                                            aria-hidden="true"
                                            className="icon-global svg-icon"
                                        >
                                            <use data-v-2284d192="" xlinkHref="#icon-global"></use>
                                        </svg>
                                        <div data-v-71b23c22=""  className="name-region">
                                            VN
                                        </div>
                                    </div>
                                </div> */}
                                {/* {code && currentUser ? (
                                    <div></div>
                                ) : (
                                    <div data-v-7c1c8a15="" className="login-widget">
                                        <button type="button" className="el-button btn-primary--new-design el-button--primary">
                                            <span>Đăng nhập</span>
                                        </button>
                                    </div>
                                )} */}
                            </div>
                        </div>
                    </div>
                </div>

                <div data-v-7c1c8a15="" className="nav-mb">
                    <div data-v-7c1c8a15="" className="wrap-search-custom">
                        <div custom-value="HEADER">
                            <div className="el-autocomplete inline-input custom-searching-button is-compact">
                                <div className="el-input is-disabled el-input--suffix">
                                    <input type="text" placeholder="Metal Slug: Awakening" className="el-input__inner" />

                                    <span className="el-input__suffix">
                                        <span className="el-input__suffix-inner">
                                            <i className="el-input__icon el-icon-search"></i>
                                        </span>
                                    </span>
                                </div>
                                <div className="el-autocomplete-suggestion el-popper false shopvnggamescom" style={{ display: 'none' }}>
                                    <div className="el-scrollbar">
                                        <div className="el-autocomplete-suggestion__wrap el-scrollbar__wrap el-scrollbar__wrap--hidden-default">
                                            <ul className="el-scrollbar__view el-autocomplete-suggestion__list"></ul>
                                        </div>
                                        <div className="el-scrollbar__bar is-horizontal">
                                            <div className="el-scrollbar__thumb" style={{ transform: 'translateX(0%)' }}></div>
                                        </div>
                                        <div className="el-scrollbar__bar is-vertical">
                                            <div className="el-scrollbar__thumb" style={{ transform: 'translateY(0%)' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div data-v-7c1c8a15="" className="el-under-header el-under-header-custom" style={{ width: '100%', height: '59px' }}></div>
        </div>
    );
}

export default Header;
