import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PaymentLogin from '~/components/PaymentLogin';
import PaymentMethod from '~/components/PaymentMethod';
import SelectPackage from '~/components/SelectPackage';
import PaymentConfirm from '~/components/PaymentConfirm';
import imageBackDropDefault from '~/assets/image/default-backdrop.webp';
import { requestUserGetAppDetail, requestUserGetAppPackages, requestUserGetAppTelcos } from '~/services/app';

function Payment() {
    const [game, setGame] = useState(null);
    const [telcos, setTelcos] = useState([]);
    const [packages, setPackages] = useState([]);

    const [telcoSelect, setTelcoSelect] = useState(null);
    const [packageSelect, setPackageSelect] = useState(null);

    const { code } = useParams();

    useEffect(() => {
        if (code) {
            document.getElementById('loadingContainer').classList.remove('hidden');

            const fetch = async () => {
                const [resultGame, resultPackage, resultTelco] = await Promise.all([
                    requestUserGetAppDetail(code),
                    requestUserGetAppPackages(code),
                    requestUserGetAppTelcos(),
                ]);

                document.getElementById('loadingContainer').classList.add('hidden');
                if (resultGame.status === 200) {
                    setGame(resultGame.data);
                    document.title = `${resultGame.data.title} | VNGGames Shop - Cổng nạp chính thức các game của VNG`;
                }
                if (resultPackage.status === 200) {
                    setPackages(resultPackage.data);
                }
                if (resultTelco.status === 200) {
                    setTelcos(resultTelco.data);
                }
            };

            fetch();
        }
    }, [code]);

    const handleGuideViewID = () => {
        document.getElementById('Popup_Container_Div').classList.toggle('hidden');
    };

    return (
        <div className="wrap-home-page wrap-home-page-redesign-ui payment">
            {/* start pup */}

            <div data-v-34089edd="" className="confirmation-container hidden" id="Popup_Container_Div" onClick={handleGuideViewID}>
                <div data-v-34089edd="" className="confirmation-content img">
                    <div data-v-34089edd="" className="confirmation-content__body img">
                        <div data-v-34089edd="">
                            <picture data-v-35b7cd99="" data-v-34089edd="" style={{ overflow: 'hidden' }}>
                                <source
                                    data-v-35b7cd99=""
                                    srcSet={game?.tutorial_url}
                                    type="image/webp"
                                    width="100%"
                                    height="100%"
                                    style={{ display: 'block' }}
                                />
                                <img
                                    data-v-35b7cd99=""
                                    src={game?.tutorial_url}
                                    alt={game?.tutorial_url}
                                    width="100%"
                                    height="100%"
                                    style={{ display: 'block', objectFit: 'cover' }}
                                />
                            </picture>
                        </div>
                    </div>
                </div>
            </div>

            {/* end pup */}

            <div data-v-f782b932="" className="wrap-backdrop-container">
                <div data-v-f782b932="" className="wrap-backdrop">
                    <picture data-v-35b7cd99="" data-v-f782b932="" className="backdrop-img" style={{ overflow: 'hidden' }}>
                        <source
                            data-v-35b7cd99=""
                            srcSet={game?.cover_url || imageBackDropDefault}
                            type="image/webp"
                            width="100%"
                            height="100%"
                            style={{ display: 'block' }}
                        />
                        <img
                            data-v-35b7cd99=""
                            src={game?.cover_url || imageBackDropDefault}
                            alt="backdropUrl"
                            width="100%"
                            height="100%"
                            style={{ display: 'block', objectFit: 'cover' }}
                        />
                    </picture>
                </div>
            </div>

            <div className="container pc">
                {/* <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(rgb(255, 255, 255) 0px, rgba(255, 255, 255, 0) 200px)',
                        top: '0px',
                        zIndex: 0,
                    }}
                ></div> */}

                <div className="wrap-banner" style={{ position: 'relative' }}>
                    <div className="contentNewBanner">
                        <div className="--iconBanner">
                            <picture data-v-35b7cd99="" style={{ overflow: 'hidden' }}>
                                <source
                                    data-v-35b7cd99=""
                                    srcSet={game?.avatar_url}
                                    type="image/webp"
                                    width="100%"
                                    height="100%"
                                    style={{ display: 'block' }}
                                />
                                <img
                                    data-v-35b7cd99=""
                                    src={game?.avatar_url}
                                    alt={game?.avatar_url}
                                    width="100%"
                                    height="100%"
                                    style={{ display: 'block', objectFit: 'cover' }}
                                />
                            </picture>
                            <div className="--iconBorder"></div>
                        </div>
                        <div>
                            <div data-v-37099789="" className="wrap-text game-title">
                                <div data-v-37099789="" className="text custom-text-style">
                                    {game?.title}
                                </div>
                                <div data-v-37099789="" className="border-text custom-text-style">
                                    {game?.title}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content content-redesign-ui">
                    <div className="content-main-flow">
                        <div className="wrap-login-package-payment">
                            <PaymentLogin game={game} />

                            <SelectPackage packages={packages} packageSelect={packageSelect} setPackageSelect={setPackageSelect} />

                            <PaymentMethod telcos={telcos} telcoSelect={telcoSelect} setTelcoSelect={setTelcoSelect} />
                        </div>

                        <PaymentConfirm telcoSelect={telcoSelect} packageSelect={packageSelect} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment;
