import request from '~/utils';

export const requestUserConfirmPayment = async (data) => {
    try {
        const res = await request.post('/chargingws/v2', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestUserGetChargingws = async (request_id) => {
    try {
        const res = await request.get('/chargingws', {
            params: {
                request_id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
