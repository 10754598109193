import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Footer from '../components/Footer';
import Header from '../components/Header';
import { dispatchSettings } from '~/redux/reducer/setting';
import { requestUserGetSettings, requestsUserCreateRequest } from '~/services/setting';

function DefaultLayout({ children }) {
    const [codeError, setCodeError] = useState('502');
    const [messageError, setMessageError] = useState('Bad Gateway');

    const params = new URL(window.location).searchParams;

    const q = params.get('q');

    const { website_status } = useSelector((state) => state.setting);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const date = localStorage.getItem('_re');

        const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        const dateMoment = moment(date);
        const currentDateMoment = moment(currentDate);

        if (currentDateMoment.diff(dateMoment, 'days') >= 1) {
            localStorage.removeItem('_re');
            navigate('/');
            window.location.reload();
        } else {
            document.getElementById('loadingContainer')?.classList.remove('hidden');

            const fetch = async () => {
                const result = await requestUserGetSettings();

                document.getElementById('loadingContainer')?.classList.add('hidden');
                if (result?.status === 403) {
                    localStorage.removeItem('_re');
                    window.location.reload();
                } else if (result?.status === 200) {
                    dispatch(dispatchSettings(result.data));

                    if (result.data.module_game.module === 'one') {
                        navigate(`/${result.data.module_game.code}`);
                    }
                } else {
                    dispatch(dispatchSettings({ module_game: { code: null, module: 'all' }, website_status: false }));
                    setCodeError(500);
                    setMessageError('Máy chủ bảo trì');
                }
            };
            fetch();
        }

        if (!date) {
            const fetch = async () => {
                const result = await requestsUserCreateRequest(q);

                if (result?.status === 200) {
                    const date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    localStorage.setItem('_re', date);
                }
            };
            fetch();
        }

        console.log('%cCopyright © : https://thegioicode.vn', 'color:green;font-size:14px;font-weight:bold');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [q]);

    return (
        <Fragment>
            {website_status ? (
                <div id="app-main" className="home pc">
                    <div className="content landscape">
                        <div className="home-page">
                            <Header />

                            {children}
                        </div>
                    </div>

                    <div className="loadingContainer hidden" id="loadingContainer">
                        <div className="loading">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>

                    <Footer />
                </div>
            ) : (
                <div className="default-website">
                    <div className="text code">{codeError}</div>
                    <div className="text message">{messageError}</div>
                </div>
            )}
        </Fragment>
    );
}

export default DefaultLayout;
