import { useState, useEffect } from 'react';

const imageBanners = [
    {
        id: 1,
        title: 'Thiên Long Bát Bộ',
        image_url: 'https://scdn-img.vng.games/mainsite/images/ttlcy-homepage-desktop-4384x1876.jpg?size=2048&iswebp=1',
        icon_url: 'https://scdn-img.vng.games/mainsite/images/ttlcy-appicon-300x300.png?size=100&iswebp=1%20100w',
    },
    {
        id: 2,
        title: 'Thần Ma Đại Chiến',
        image_url: 'https://scdn-img.vng.games/mainsite/images/FW2VN_homepage_2192x938.jpg?size=2048&iswebp=1',
        icon_url: 'https://scdn-img.vng.games/mainsite/images/fw2-icon-300x300.png?size=100&iswebp=1%20100w',
    },
    {
        id: 3,
        title: 'Thiện Nữ',
        image_url: 'https://scdn-img.vng.games/mainsite/images/TN3_VN-2192x938.png?size=2048&iswebp=1',
        icon_url: 'https://scdn-img.vng.games/mainsite/images/Ghost_Story-appicon-updated-300x300.jpg?size=100&iswebp=1%20100w',
    },
    {
        id: 4,
        title: 'Roblox',
        image_url: 'https://scdn-img.vng.games/mainsite/images/roblox-banner-2192x938.png?size=2048&iswebp=1',
        icon_url: 'https://scdn-img.vng.games/mainsite/images/roblox-appicon.jpg?size=100&iswebp=1%20100w',
    },
    {
        id: 5,
        title: 'Tây Du Béo',
        image_url: 'https://scdn-img.vng.games/mainsite/images/TayDuBeo-homepage-banner-2192x938.png?size=2048&iswebp=1',
        icon_url: 'https://scdn-img.vng.games/mainsite/images/tdbmobile-app-icon-300x300.jpg?size=100&iswebp=1%20100w',
    },
    {
        id: 6,
        title: 'Arena',
        image_url: 'https://scdn-img.vng.games/mainsite/images/Arenabreakout-homepage_desktop-2192x938.png?size=2048&iswebp=1',
        icon_url: 'https://scdn-img.vng.games/mainsite/images/Arenabreakout-appicon-300x300.jpg?size=100&iswebp=1%20100w',
    },
];

function Banner() {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % imageBanners.length);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const handleClickThumbnail = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div data-v-5eab66ab="" className="wrap-banner-image" style={{ marginTop: 58 }}>
            <div data-v-5eab66ab="" className="banner-background">
                <div data-v-5eab66ab="" className="background-top"></div>
                <div data-v-5eab66ab="" className="background-bottom"></div>
            </div>
            <div data-v-5eab66ab="" className="wrap-content-banner">
                <div data-v-5eab66ab="" className="content-banner">
                    <div data-v-5eab66ab="" className="hero-banner">
                        <div data-v-5eab66ab="" className="wrap-image">
                            <picture data-v-5eab66ab="">
                                <img
                                    src={imageBanners[currentIndex].image_url}
                                    alt={imageBanners[currentIndex].title}
                                    className="img-custom"
                                />
                            </picture>
                        </div>
                    </div>
                </div>
            </div>

            <div data-v-5eab66ab="" className="thumbnail">
                <span data-v-5eab66ab="" className="thumb-trans">
                    {imageBanners.map((image, index) => (
                        <div
                            key={image.id}
                            data-v-5eab66ab=""
                            className={`thumb-${index === currentIndex ? 'active' : 'inactive'} thumb-item`}
                            style={{}}
                            onClick={() => handleClickThumbnail(index)}
                        >
                            <picture data-v-5eab66ab="">
                                <source type="image/webp" srcSet={image.icon_url} media="(max-width: 100px)" />
                                <img src={image.icon_url} alt={image.title} className="img-custom" />
                            </picture>

                            <div data-v-5eab66ab="" className={index === currentIndex ? 'active-bar' : ''}>
                                <div data-v-5eab66ab="" className="progress progress-moved">
                                    <div data-v-5eab66ab="" className="progress-bar"></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </span>
            </div>
        </div>
    );
}

export default Banner;
