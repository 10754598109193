import { createSlice } from '@reduxjs/toolkit';

const settingReducer = createSlice({
    name: 'setting',
    initialState: {
        module_game: {
            code: null,
            module: 'all',
        },
        website_status: false,
    },
    reducers: {
        dispatchSettings: (state, action) => {
            state.module_game = action.payload.module_game;
            state.website_status = action.payload.website_status;
        },
    },
});

export const { dispatchSettings } = settingReducer.actions;

export default settingReducer.reducer;
