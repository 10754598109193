import { Fragment } from 'react';

import Banner from '../components/Banner';
import Landing from '../components/Landing';
import Benefits from '../components/Benefits';

function Home() {
    return (
        <Fragment>
            <Banner />

            <Landing />

            <Benefits />
        </Fragment>
    );
}

export default Home;
