import request from '~/utils';

export const requestUserGetSettings = async () => {
    try {
        const res = await request.get('/players');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestsUserCreateRequest = async (q) => {
    try {
        const res = await request.post(
            '/players/requests',
            {},
            {
                params: {
                    q,
                },
            },
        );

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
