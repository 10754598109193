import Home from '~/layouts/Home';
import NotFound from '~/layouts/NotFound';
import Result from '~/layouts/components/Result';
import Payment from '~/layouts/components/Payment';

const publicRoutes = [
    { path: '/', component: Home },
    { path: '/:code', component: Payment },
    { path: '*', component: NotFound, layout: null },
    { path: '/:code/result/:request_id', component: Result },
];

export default publicRoutes;
